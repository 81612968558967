import AOS from 'aos';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min';
import Swiper from 'swiper/swiper-bundle.esm.browser.min';
import 'swiper/swiper-bundle.min.css';
// import Parallax from 'parallax-js';
// import Shuffle from 'shufflejs';
// import Chart from 'chart.js/auto';
// import noUiSlider from 'nouislider';
// import flatpickr from 'flatpickr';
// import rangePlugin from 'nouislider/plugins/range';
// import Cleave from 'cleave.js';
// import FullCalendar from '@fullcalendar/core';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';
import jarallax from 'jarallax/dist/jarallax.min';
// import lgZoom from 'lightgallery/plugins/zoom';
// import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import lightGallery from 'lightgallery';
// import lgThumbnail from 'lightgallery/plugins/thumbnail'; 

document.addEventListener("turbolinks:load", () => {
  themeModeSwitch();
  animateOnscroll();
  carousel();
});

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
/**
 * Around | Multipurpose Bootstrap HTML Template
 * Copyright 2022 Createx Studio
 * Theme core scripts
 * 
 * @author Createx Studio
 * @version 3.1.0
 */

(function () {
  'use strict';
  /**
   * Theme Mode Switch
   * Switch betwen light/dark mode. The chosen mode is saved to browser's local storage
  */
  var themeModeSwitch = function () {
    var modeSwitch = document.querySelector('[data-bs-toggle="mode"]');
    if (modeSwitch === null) return;
    var checkbox = modeSwitch.querySelector('.form-check-input');
    if (mode === 'dark') {
      root.classList.add('dark-mode');
      checkbox.checked = true;
    } else {
      root.classList.remove('dark-mode');
      checkbox.checked = false;
    }
    modeSwitch.addEventListener('click', function (e) {
      if (checkbox.checked) {
        root.classList.add('dark-mode');
        window.localStorage.setItem('mode', 'dark');
      } else {
        root.classList.remove('dark-mode');
        window.localStorage.setItem('mode', 'light');
      }
    });
  }();

  /**
   * Add solid background to fixed to top navigation bar
  */

  var stickyNavbar = function () {
    var navbar = document.querySelector('.navbar.fixed-top');
    if (navbar == null) return;
    var navbarClass = navbar.classList,
      scrollOffset = 20;
    var navbarStuck = function navbarStuck(e) {
      if (e.currentTarget.pageYOffset > scrollOffset) {
        navbar.classList.add('navbar-stuck');
        if (navbar.classList.contains('navbar-ignore-dark-mode')) {
          navbar.classList.remove('ignore-dark-mode');
        }
      } else {
        navbar.classList.remove('navbar-stuck');
        if (navbar.classList.contains('navbar-ignore-dark-mode')) {
          navbar.classList.add('ignore-dark-mode');
        }
      }
    };

    // On load
    window.addEventListener('load', function (e) {
      navbarStuck(e);
    });

    // On scroll
    window.addEventListener('scroll', function (e) {
      navbarStuck(e);
    });
  }();

  /**
   * Animation on scroll (AOS)
   * 
   * @requires https://github.com/michalsnik/aos
  */

  var animateOnscroll = function () {
    var animationToggle = document.querySelector('[data-aos]');
    if (animationToggle === null) return;
    AOS.init();
  }();

  /**
   * Anchor smooth scrolling
   * @requires https://github.com/cferdinandi/smooth-scroll/
  */

  var smoothScroll = function () {
    var selector = '[data-scroll]',
      fixedHeader = '[data-scroll-header]',
      scroll = new SmoothScroll(selector, {
        speed: 800,
        speedAsDuration: true,
        offset: function offset(anchor, toggle) {
          return toggle.dataset.scrollOffset || 20;
        },
        header: fixedHeader,
        updateURL: false
      });
  }();

  /**
   * Animate scroll to top button in/off view
  */

  var scrollTopButton = function () {
    var button = document.querySelector('.btn-scroll-top'),
      scrollOffset = 450;
    if (button == null) return;
    var offsetFromTop = parseInt(scrollOffset, 10),
      progress = button.querySelector('svg circle'),
      length = progress.getTotalLength();
    progress.style.strokeDasharray = length;
    progress.style.strokeDashoffset = length;
    var showProgress = function showProgress() {
      var scrollPercent = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight),
        draw = length * scrollPercent;
      progress.style.strokeDashoffset = length - draw;
    };
    window.addEventListener('scroll', function (e) {
      if (e.currentTarget.pageYOffset > offsetFromTop) {
        button.classList.add('show');
      } else {
        button.classList.remove('show');
      }
      showProgress();
    });
  }();

  /**
   * Content carousel with extensive options to control behaviour and appearance
   * @requires https://github.com/nolimits4web/swiper
  */

  var carousel = function () {
    // forEach function
    var forEach = function forEach(array, callback, scope) {
      for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]); // passes back stuff we need
      }
    };

    // Carousel initialisation
    var carousels = document.querySelectorAll('.swiper');
    var activeBtnExpertiseSection = document.querySelectorAll('.expertise-btn');
    forEach(carousels, function (index, value) {
      var options;
      if (value.dataset.swiperOptions != undefined) options = JSON.parse(value.dataset.swiperOptions);

      // Thumbnails
      if (options.thumbnails) {
        var images = options.thumbnails.images;
        options = Object.assign({}, options, {
          pagination: {
            el: options.thumbnails.el,
            clickable: true,
            bulletActiveClass: 'active',
            renderBullet: function renderBullet(index, className) {
              return "<li class='swiper-thumbnail ".concat(className, "'>\n              <img src='").concat(images[index], "' alt='Thumbnail'>\n            </li>");
            }
          }
        });
      }
      var swiper = new Swiper(value, options);

      // Controlled slider
      if (options.controlledSlider) {
        var controlledSlider = document.querySelector(options.controlledSlider),
          controlledSliderOptions;
        if (controlledSlider.dataset.swiperOptions != undefined) controlledSliderOptions = JSON.parse(controlledSlider.dataset.swiperOptions);
        var swiperControlled = new Swiper(controlledSlider, controlledSliderOptions);
        swiper.controller.control = swiperControlled;
      }

      // Binded content
      if (options.bindedContent) {
        swiper.on('activeIndexChange', function (e) {
          var targetItem = document.querySelector(e.slides[e.activeIndex].dataset.swiperBinded),
            previousItem = document.querySelector(e.slides[e.previousIndex].dataset.swiperBinded);
          previousItem.classList.remove('active');
          targetItem.classList.add('active');
        });
      }
      forEach(activeBtnExpertiseSection, function (index, button) {
        button.addEventListener("click", function () {
          swiper.slideTo(index);
        });
      });
    });
  }();

  /**
   * Gallery like styled lightbox component for presenting various types of media
   * @requires https://github.com/sachinchoolur/lightGallery
  */

  var gallery = function () {
    var gallery = document.querySelectorAll('.gallery-item');
    if (gallery.length) {
      for (var i = 0; i < gallery.length; i++) {
        lightGallery(gallery[i], {
          selector: 'this',
          plugins: [lgVideo],
          licenseKey: 'D4194FDD-48924833-A54AECA3-D6F8E646',
          download: false,
          closeOnTap: true,
          controls: false,
          counter: false,
          loadYouTubePoster: true,
          autoplayVideoOnSlide: true,
          youtubePlayerParams: {
            modestbranding: 1,
            showinfo: 0,
            rel: 0,
            controls: 1,
            enablejsapi: 1,
            playsinline: 1,
          },
        });
      }
    }
  }();

  // /**
  //  * Update the text of the label when radio button / checkbox changes
  // */

  // var bindedLabel = function () {
  //   var toggleBtns = document.querySelectorAll('[data-binded-label]');
  //   for (var i = 0; i < toggleBtns.length; i++) {
  //     toggleBtns[i].addEventListener('change', function () {
  //       var target = this.dataset.bindedLabel;
  //       try {
  //         document.getElementById(target).textContent = this.value;
  //       } catch (err) {
  //         if (err.message = "Cannot set property 'textContent' of null") {
  //           console.error('Make sure the [data-binded-label] matches with the id of the target element you want to change text of!');
  //         }
  //       }
  //     });
  //   }
  // }();

  /**
   * Bind different content to different navs or even accordion.
  */

  // var bindedContent = function () {
  //   var clickToggles = document.querySelectorAll('[data-binded-content]'),
  //     scrollToggles = document.querySelectorAll('[data-scroll-binded]'),
  //     bindedContent = document.querySelector('.binded-content');

  //   // Get target element siblings
  //   var getSiblings = function getSiblings(elem) {
  //     var siblings = [],
  //       sibling = elem.parentNode.firstChild;
  //     while (sibling) {
  //       if (sibling.nodeType === 1 && sibling !== elem) {
  //         siblings.push(sibling);
  //       }
  //       sibling = sibling.nextSibling;
  //     }
  //     return siblings;
  //   };

  //   // Change binded content function
  //   var changeBindedContent = function changeBindedContent(target) {
  //     var targetEl = document.querySelector(target),
  //       targetSiblings = getSiblings(targetEl);
  //     targetSiblings.map(function (sibling) {
  //       sibling.classList.remove('active');
  //     });
  //     targetEl.classList.add('active');
  //   };

  //   // Change binded content on click
  //   for (var i = 0; i < clickToggles.length; i++) {
  //     clickToggles[i].addEventListener('click', function (e) {
  //       changeBindedContent(e.currentTarget.dataset.bindedContent);
  //     });
  //   }
  // }();
  var userHandlers = function () {
    var showLess = document.querySelectorAll('.collapse-label') || [];
    _toConsumableArray(showLess).map(function (item) {
      item.addEventListener("click", function () {
        if (item.classList.contains("collapsed")) {
          var parentSelector = item.getAttribute('data-parent');
          var parentSection = document.querySelector(parentSelector) || item.closest("section");
          parentSection.scrollIntoView({
            behavior: "smooth"
          });
        }
      });
    });
  }();

  /**
   * Around | Multipurpose Bootstrap HTML Template
   * Copyright 2022 Createx Studio
   * Theme core scripts
   *
   * @author Createx Studio
   * @version 3.0.0
  */
})();
